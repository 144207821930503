export default function FaqIcon(props) {
  return (
    <svg
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      className={props.classname}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g fill="currentColor" fillRule="nonzero">
              <path
                d="M21.155 19.74c3.793-4.463 3.793-11.017 0-15.481l.86-.86c.39-.39.39-1.024 0-1.415-.391-.39-1.025-.39-1.415 0l-.86.86c-4.464-3.792-11.018-3.792-15.482 0l-.86-.86c-.393-.378-1.016-.372-1.402.014-.385.386-.39 1.01-.011 1.402l.86.86c-3.793 4.464-3.793 11.018 0 15.482l-.86.86c-.26.251-.364.623-.272.973.092.35.365.622.715.713.35.092.721-.013.972-.273l.86-.86c4.464 3.793 11.018 3.793 15.482 0l.86.86c.393.379 1.016.373 1.402-.013.385-.386.39-1.01.01-1.402l-.86-.86zM22 12c.002 2.306-.798 4.542-2.262 6.324l-3.571-3.571c1.11-1.667 1.11-3.839 0-5.506l3.57-3.571C21.203 7.458 22.003 9.693 22 12zM9 12c0-1.657 1.343-3 3-3s3 1.343 3 3-1.343 3-3 3-3-1.343-3-3zm3-10c2.306-.002 4.542.798 6.324 2.262l-3.571 3.57c-1.667-1.11-3.839-1.11-5.506 0l-3.571-3.57C7.458 2.798 9.693 1.998 12 2zM2 12c-.002-2.307.798-4.542 2.262-6.324l3.57 3.57c-1.11 1.668-1.11 3.84 0 5.507l-3.57 3.57C2.798 16.543 1.998 14.307 2 12zm10 10c-2.307.002-4.542-.798-6.324-2.262l3.57-3.571c1.668 1.11 3.84 1.11 5.507 0l3.57 3.57C16.543 21.203 14.307 22.003 12 22z"
                transform="translate(-66.000000, -262.000000) translate(66.000000, 262.000000) translate(3.000000, 3.000000)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
