import { useState } from 'react';
import './App.css';
import './index.css';
import Header from './components/Header';
import Main from './components/Main';
import { Modal, Stack, Typography, Box } from '@mui/material';


function App() {
    const [openSidebar, setOpenSidebar] = useState(false);
    const toggleSidebar = () => {
        setOpenSidebar(!openSidebar);
    };
    const [adStatus, setAdStatus] = useState(true);


    return (
        <div className="min-h-screen flex-col justify-center overflow-hidden bg-gray-50 background mainContainer">
            <Header toggle={() => toggleSidebar()} />
            <Main openSidebar={openSidebar} />
            <div className='footer'></div>

            <Modal open={adStatus} onClose={() => setAdStatus(false)} >
                <Box sx={{ ...style, maxHeight: "900px" }}>
                    <img src="./superr.gif" width={"750px"} height={"500px"} />
                    <div style={{ paddingTop: "20px", paddingBottom: "10px", paddingInline: "10px", textAlign: "center" }}>
                        We are still here and we are working on something new asap❤️ <br />
                        Spaghetti is on super saiyan mode rn⚡<br />
                    </div>
                </Box>
            </Modal>
        </div>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    // pt: 2,
    // px: 4,
    // pb: 3,
};

export default App;
