export default function DashboardIcon(props) {
  return (
    <svg
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      className={props.classname}
    >
      <g fill="none" fillRule="evenodd">
        <g>
          <g>
            <g fill="currentColor" fillRule="nonzero">
              <path
                d="M23.9 10.455C23.085 4.177 17.534-.387 11.217.025 4.901.44-.009 5.688 0 12.019c-.012 3.3 1.35 6.456 3.76 8.712.91.843 2.111 1.304 3.353 1.288h9.767c1.312.003 2.573-.51 3.51-1.43 2.706-2.633 4.007-6.39 3.51-10.133zm-4.909 8.7c-.562.554-1.321.864-2.11.863H7.112c-.73.015-1.44-.25-1.98-.741-2.008-1.88-3.142-4.51-3.133-7.26.002-2.847 1.216-5.558 3.338-7.456 1.823-1.645 4.193-2.551 6.648-2.544.392 0 .784.023 1.174.067 3.828.444 7.06 3.05 8.305 6.697 1.245 3.647.283 7.685-2.474 10.379v-.005zM7.807 16.303c.395.386.402 1.02.015 1.415-.386.394-1.02.401-1.415.015-2.321-2.275-3.037-5.727-1.81-8.736C5.826 5.987 8.75 4.02 12 4.017c.383 0 .765.027 1.143.082.263.037.5.178.658.39.16.213.227.48.19.743-.036.264-.176.502-.389.662-.213.16-.482.227-.745.186-2.567-.367-5.08.957-6.228 3.282-1.148 2.325-.673 5.125 1.178 6.94zm12.112-5.428c.054.378.081.76.081 1.143.007 2.153-.863 4.216-2.408 5.716-.395.386-1.03.379-1.416-.016-.386-.396-.38-1.03.016-1.416 1.37-1.336 2.019-3.247 1.744-5.141-.079-.548.3-1.055.848-1.134.547-.079 1.054.3 1.133.848h.002zm-5.993.63c.248.89-.136 1.835-.934 2.301-.798.466-1.809.335-2.462-.318-.653-.653-.784-1.665-.318-2.463.465-.797 1.41-1.181 2.3-.933l3.781-3.781c.251-.26.623-.365.973-.273.35.091.622.364.714.714.091.35-.013.721-.273.973l-3.78 3.78z"
                transform="translate(-66.000000, -130.000000) translate(66.000000, 130.000000) translate(3.000000, 4.000000)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
