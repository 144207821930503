export default function DiamondIcon(props) {
  return (
    <svg
      height="30"
      viewBox="0 0 30 30"
      width="30"
      xmlns="http://www.w3.org/2000/svg"
      className={props.classname}
    >
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor" fillRule="nonzero">
          <g>
            <g>
              <path
                d="M23.22 5.743l-3.01-4.068C19.459.625 18.247.001 16.956 0h-9.91C5.76-.001 4.554.615 3.8 1.656L.736 5.753C-.29 7.198-.24 9.146.859 10.537L9.65 22.865c.568.719 1.434 1.137 2.35 1.135.932-.002 1.81-.436 2.378-1.175L23.1 10.634c1.149-1.413 1.198-3.423.12-4.891zm-4.627-2.892l3.018 4.078c.016.022.02.048.036.071h-4.892l-1.428-5h1.629c.651.002 1.261.32 1.637.851zM12 19.118L9.3 9h5.4L12 19.118zM9.327 7l1.428-5h2.492l1.428 5H9.327zm-3.91-4.163c.377-.525.983-.836 1.629-.837h1.629L7.247 7H2.319c.014-.022.018-.049.034-.07l3.065-4.093zM2.45 9.325c-.072-.102-.134-.21-.185-.325h4.969l2.994 11.23L2.449 9.325zM13.772 20.24l3-11.24h4.986c-.065.15-.146.29-.243.421L13.772 20.24z"
                transform="translate(-66.000000, -196.000000) translate(66.000000, 196.000000) translate(3.000000, 3.000000)"
              ></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
